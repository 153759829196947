import particlesOptions from "./particles.json";
import Particles from "react-tsparticles";
import { Link } from "react-router-dom";


export default function Hero2() {
  return (
      <>
    <div className="relative bg-gray-50 mt-[-72px] md:mt-[-76px] lg:pb-12 h-screen">
    <Particles options={particlesOptions}/>
      <div className=" overflow-hidden  relative h-full  content-center items-center lg:grid">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl grid  lg:grid-cols-2 lg:gap-24 lg:py-36">
          <div>
            <div className="mt-48 lg:mt-20">

              <div className="mt-6 sm:max-w-xl">
                <img src="/img/auxo-single.svg" className="w-16 mb-4"/>
                <h1 className="text-2xl font-extrabold text1 tracking-tight sm:text-4xl">
                You know your business.<br/> <span className=" text-4xl sm:text-5xl alttext1">We know how to grow it.</span>
                </h1>

              </div>
              <div className="mt-12 sm:max-w-lg w-full flex gap-4">

                <div className="mt-0">
                  <Link to="/contact">
                  <button
                    type="submit"
                    className="block w-full rounded-md border-2 border-transparent px-5 py-3 back1 text-base font-medium text-white shadow hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-10"
                  >
                    Get Started
                  </button>
                  </Link>
                  
                </div>
                <a
                   href="#more"
                    className="block text-center hover:bg-slate-500/10 rounded-md  px-5 py-3 back1/0 border-white/10 bg-white backdrop-blur-sm border-2 text-base font-medium  shadow hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-10"
                  >
                   Learn more
                  </a>
              </div>
             
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-6 md:py-12 sm:relative mt-20 lg:mt-36 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen  rounded-l-3xl lg:left-80 lg:right-0 lg:w-full bg-slate-500/10 backdrop-blur-sm" />
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
              </svg>
            </div>
            <div className="relative grid 2xl:block items-center pl-4  sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              <img
                className=" -mt-12 sm:mt-0 md:h-[500px]  rounded-md shadow-xl ring-1 ring-black ring-opacity-5 2xl:h-auto 2xl:w-auto lg:max-w-6xl"
                src="/img/dashboard.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
