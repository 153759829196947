
const About = () => {
  return (
      <div className="bg-gray-50 px-4 sm:px-6">
      <div className="max-w-7xl grid mx-auto py-16 lg:py-36 pb-24 lg:pb-48">
          <h2 className="text-center text-4xl text-black font-bold mb-8">Meet the team.</h2>
        <div className="grid md:grid-cols-2 relative z-[1]">
            <div className="max-w-sm md:max-w-full mx-auto grid justify-items-center px-8 lg:px-16 py-8 lg:p-20">
                <img src="/img/chrisc.jpg" className="rounded-full shadow-lg mb-8 border-4 " />
                <h2 className="text-2xl sm:text-xl lg:text-2xl font-bold text-center">Chris Cue</h2>
                <p className="text1 ">COO + Bz Dev.</p>
            </div>

            <div className="max-w-sm md:max-w-full mx-auto grid justify-items-center px-8 lg:px-16 py-8 lg:p-20">
                <img src="/img/stevem.jpg" className="rounded-full shadow-lg mb-8 border-4 " />
                <h2 className="text-2xl sm:text-xl lg:text-2xl font-bold text-center">Steve Marshall</h2>
                <p className="text1 ">Co-founder</p>
            </div>
        </div>
        <div className="rounded-xl bg-gray-50 relative z-[0] grid justify-items-center ">
                <h2 className=" w-full text-2xl font-bold  text-center pb-4 pt-2">Ready to Grow your revenue?</h2>
                <div className="grid sm:grid-cols-2 gap-8 sm:gap-24 pt-12 px-4">
                    <div>
                        <div>
                            <div className="flex items-center gap-4">
                                <img src="/img/check-solid.svg" className="w-4 sm:w-8 "/><p>Over 50 years of sales experience</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-4">
                                <img src="/img/check-solid.svg" className="w-4 sm:w-8 "/><p>Management experience</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-4">
                                <img src="/img/check-solid.svg" className="w-4 sm:w-8 "/><p>Process gurus</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-4">
                                <img src="/img/check-solid.svg" className="w-4 sm:w-8 "/><p>Two successful exits/acquisitions</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="flex items-center gap-4">
                                <img src="/img/check-solid.svg" className="w-4 sm:w-8 "/><p>Serial Entrepreneurs</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-4">
                                <img src="/img/check-solid.svg" className="w-4 sm:w-8 "/><p>Customer service</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-4">
                                <img src="/img/check-solid.svg" className="w-4 sm:w-8 "/><p>Finance and legal expertise</p>
                            </div>
                        </div>
                        <div>
                            <div className="flex items-center gap-4">
                                <img src="/img/check-solid.svg" className="w-4 sm:w-8 "/><p>Merchant processing experts</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    </div>
  )
}

export default About