import React from 'react'
import Scaling from '../components/Scaling'
import About from '../components/About'
import ContactHome from '../components/Contact'
import Timeline from '../components/Timeline'


const AboutPage = () => {
  return (
    <div>
        <div>
          </div>
        <About/>
        <ContactHome/>
    </div>
  )
}

export default AboutPage