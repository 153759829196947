import {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'

const WhyOutsource = () => {

    const [loaded,setLoaded]=useState(false)
    const [clients,setClients]=useState([
        {
            id: "1-d",
            name:"Sally Smith",
            phone: "(776) 432-3987"

        },
        {
            id: "2-d",
            name:"Bob Tappard",
            phone: "(765) 484-2292"

        },
        {
            id: "3-d",
            name:"Jacob Henderson",
            phone: "(411) 123-4545"

        },
        {
            id: "4-d",
            name:"Max Wells",
            phone: "(432) 675-3212"

        }, 
        {
            id: "5-d",
            name:"Noah Randen",
            phone: "(679) 333-2345"

        }, 
    ])



  return (
    <div className='py-24 xl:py-48 bg-gray-50 px-4 sm:px-6 '>
        <div className='max-w-7xl mx-auto grid xl:grid-cols-2 gap-12 items-center'>
            <div>
                <h2 className='text-4xl md:text-5xl mb-3 '>Accelerate your growth.</h2>
                <p>Auxo combines proven strategies to find your target market, capture your ideal customer, create aggressive price structures to increase revenue, automate your marketing, streamline your sales process, and increase customer retention.  As companies seek greater efficiencies through automation via B2B software solutions, the demand for your software is growing. However, competition makes now a critical time to get in front of your ideal customers.</p>
                <Link
                    to="/contact"
                    className="hidden sm:inline-flex font-[atten-new] mt-4  items-center w-full sm:w-auto justify-center px-4 py-2  rounded-md shadow-sm text-base font-medium text-white altback1 hover:opacity-90 border-2 border-blue-500/0"
                    >
                    Get more clients.
                    </Link>
            </div>

            <div className='bg-gray-200 rounded-t-2xl relative  '>
                <div className='back1 py-4 mt-9 px-4 grid grid-cols-2 items-center' >
                    <span className='font-bold text-white font-[atten-new]'>New Clients</span>
                    <img src="/img/auxo-single-white.svg" className='w-8 justify-self-end'/>

                </div>
                <div className='px-2 sm:px-4 grid grid-cols-5 sm:grid-cols-3 mt-9 mb-2'>
                    <span className='font-bold text-xs col-span-2 sm:col-span-1'>Name</span>
                    <span className='font-bold text-xs col-span-2 sm:col-span-1'>Phone</span>
                    <span className='font-bold text-xs justify-self-end'>Actions</span>

                </div>
                <div className='overflow-hidden h-[232px] transition-all'>
                {clients.map((client,index) => (
                    

                <div key={client.id} className={'py-4 px-2 sm:px-4 grid grid-cols-5 sm:grid-cols-3 items-center border-y border-gray-300 ' + (index===0 ? "new-client" :index===1 ? "old-client1":index===2 ? "old-client2" :index===3 ? "old-client3":"")}>
                    <span className='font-bold shield text-xs sm:text-base col-span-2 sm:col-span-1'>{client.name}</span>
                    <span className='flex shield text-xs sm:text-base col-span-2 sm:col-span-1'><img className='w-3 mr-2' src="/img/mobile-solid.svg"/>{client.phone}</span>
                    <div className=' justify-self-end grid grid-cols-2 gap-4'>
                        <img className='w-2' src="/img/dollar-sign-solid.svg"/>
                        <img className='w-3' src="/img/circle-info-solid.svg"/>
                    </div>
                </div>
                ))}
                </div>
                <div className='absolute top-3 left-3 grid grid-cols-3 gap-2'>
                    <div className='rounded-full h-3 w-3 bg-red-500'></div>
                    <div className='rounded-full h-3 w-3 bg-yellow-500'></div>
                    <div className='rounded-full h-3 w-3 bg-green-500'></div>
                </div>
                <div className='absolute h-36 bottom-0 left-0 w-full bg-gradient-to-t from-gray-50 to-gray-50/0'>
                </div>
                <div className='absolute w-48 right-[-100px] bottom-0 hidden 2xl:block'><img src="/img/salesman.svg"/></div>
            </div>
        </div>
        <Link
                    to="/contact"
                    className="sm:hidden w-full mt-12 inline-flex font-[atten-new]   items-center sm:w-auto justify-center px-4 py-2  rounded-md shadow-sm text-base font-medium text-white altback1 hover:opacity-90 border-2 border-blue-500/0"
                    >
                    Get more clients.
                    </Link>
    </div>
  )
}

export default WhyOutsource