import { useState,useRef,useEffect } from 'react'
import { Switch } from '@headlessui/react'
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function ContactHome() {

  const form = useRef();
  const [disabled,setDisabled] =useState(true)
  const [sent,setSent] =useState(false)


  function onChange(value) {
    setDisabled(false)
  }

const sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_d1xu9gj', 'template_jvujose', form.current, '7nqZzQF1p3YWyhVN6')
    .then((result) => {
        console.log(result.text);
        setSent(true)
    }, (error) => {
        console.log(error.text);
    });
};


  return (
    <div className="bg-gray-50 pt-16 pb-36 px-4 overflow-hidden sm:px-6 lg:px-8 lg:pt-24 lg:pb-36">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full transform translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <svg
          className="absolute right-full bottom-0 transform -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Connect with us and start growing your revenue today.</h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
          Contact us below and we will be back with you as soon as possible.
          </p>
        </div>
        <div className={"mt-12  justify-items-center "  + (sent ? "grid" : "hidden")}>
            <img src="/img/mail.svg" className='w-24 mb-4' />
            <h2 className='font-semibold'>Sent</h2>
            <p>We'll be in touch soon.</p>
        </div>
        <div className={"mt-12 "  + (sent ? "hidden" : "grid")}>
          <form ref={form} onSubmit={sendEmail} method="POST" className={"grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 "}>
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                First name
              </label>
              <div className="mt-1">
                <input
                required
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                Last name
              </label>
              <div className="mt-1">
                <input
                required
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                Company
              </label>
              <div className="mt-1">
                <input
                required
                  type="text"
                  name="company"
                  id="company"
                  autoComplete="organization"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                required
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="temper" className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <div className="mt-1">
                <input
                required
                  type="text"
                  name="temper"
                  id="temper"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="py-3 px-4 block w-full shadow-sm focus:ring-slate-500 focus:border-slate-500 border border-gray-300 rounded-md"
                  defaultValue={''}
                />
              </div>
            </div>
            <div className='w-full grid justify-center col-span-2 py-6'>
            <ReCAPTCHA
              sitekey="6LdLLW0fAAAAAJGkRmEHqo0uCxHPkMJavnRaV8jK"
              onChange={onChange}
            />
            </div>
            <div className="sm:col-span-2">
              <button
                disabled={disabled}
                type="submit" 
                className="w-full inline-flex items-center disabled:opacity-40 justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white back1 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-600"
              >
                Let's talk.
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
