import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import HomeHeader from './HeaderTransparent'

import { useEffect,useState } from 'react'

const Layout = () => {

    const [stick, setStick] = useState(false);


    useEffect(() => {
      if (typeof window !== "undefined") {
        window.addEventListener("scroll", () =>{
          setStick(window.pageYOffset > 10)
        }

        );
          
      }
    }, []);

  return (
    <>
        <header className={"sticky top-0 z-[999] transition-all	bg-gray-50/70 backdrop-blur-md"}>
            <Header stick={stick}/>
        </header>
         <main className="">
            <Outlet/>
         </main>
         <div>
             <Footer/>
         </div>
    </>
  )
}

export default Layout