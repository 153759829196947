import React from 'react'
import { Link } from 'react-router-dom'

const Calculate = () => {
  return (
    <div className='py-24 xl:py-48 bg-white px-4 sm:px-6 lg:px-8'>
       <h2 className=' text-4xl md:text-5xl sm:text-center'>Calculate your potential ROI with Auxo.</h2>
        <p className='font-normal text-base text1  mt-2 sm:text-center'>Use our online calculators to instantly see what we can do for you.</p>

        <div className='max-w-7xl grid md:grid-cols-2 mx-auto justify-center gap-16 mt-16'>
            
            <Link to="/calculate" className='p-8 sm:p-4 rounded-3xl shadow-lg w-full sm:w-72 sm:h-72 bg-gray-50 justify-items-center items-center grid justify-self-end hover:-translate-y-4 transition-all'>
                <img src='/img/saas1.svg' className='h-32'/>
                <div>
                <p className='text-sm text-center'>Calculate</p>
                <h2>SaaS ROI</h2>
                <p className='text-sm text-center'>With Auxo</p>
                </div>
            </Link>
            <Link to="/calculate" className='p-8 sm:p-4 rounded-3xl shadow-lg w-full sm:w-72 sm:h-72 bg-gray-50 justify-items-center items-center grid  hover:-translate-y-4 transition-all'>
                <img src='/img/pay1.svg' className='h-32'/>
                <div>
                <p className='text-sm text-center'>Calculate</p>
                <h2>Payments ROI</h2>
                <p className='text-sm text-center'>With Auxo</p>

                </div>
            </Link>
        </div>

    </div>
  )
}

export default Calculate