import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import HeaderTransparent from './HeaderTransparent'

import { useEffect,useState } from 'react'

const LayoutTransparent = () => {

    const [stick, setStick] = useState(false);
    const [headerOpen, setHeaderOpen] = useState(false);


    useEffect(() => {
        if (typeof window !== "undefined") {
            if(window.location.pathname==="/"){
          window.addEventListener("scroll", () =>{
            setStick(window.pageYOffset > 50)
          }

          );
            }
        }
      }, []);

  return (
    <>
        <header className={"sticky top-0 z-[999] transition-all	" + (stick ? "bg-gray-50/70 backdrop-blur-md" : "bg-none  ")}>
            <HeaderTransparent stick={stick} headerOpen={headerOpen} setHeaderOpen={setHeaderOpen}/>
        </header>
         <main className="">
            <Outlet/>
         </main>
         <div>
             <Footer/>
         </div>
    </>
  )
}

export default LayoutTransparent