import React from "react";
import ContactHome from "../components/Contact";
import CTA from "../components/CTA";
import Scaling from "../components/Scaling";
import ThreeColumn from "../components/ThreeColumn";
import Timeline from "../components/Timeline";
import TimelineMobile from "../components/TimelineMobile";
import Contact from "./Contact";

const Story = () => {
  return (
    <>
    <ThreeColumn/>
      <div className="hidden lg:block">
        <Timeline />
      </div>
      <div className="lg:hidden block">
        <TimelineMobile />
      </div>
      <Scaling/>
      <ContactHome/>
    </>
  );
};

export default Story;
