import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContactHome from "../components/Contact";
import ThreeColumn from "../components/ThreeColumn";
import Timeline from "../components/Timeline";
import TimelineMobile from "../components/TimelineMobile";

const Calculate = () => {
  const [saas, setSaas] = useState("");
  const [outSaas, setOutSaas] = useState("$0");
  const [shown, setShown] = useState(false);
  const [smallerSaas, setSmallerSaas] = useState(0);

  const [total, setTotal] = useState("$0");

  useEffect(() => {
    const temp = saas * 9.5;
    setOutSaas(
      temp
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
    if (temp > 9999999) {
      setSmallerSaas(2);
    }
    if (temp > 99999) {
      setSmallerSaas(1);
    }
    if (temp <= 99999) {
      setSmallerSaas(0);
    }
    if (saas !== "") {
      setShown(true);
    } else {
      setShown(false);
    }
  }, [saas]);

  const handleInput = (num) => {
    if (num > 999999999999) {
      num = 999999999999;
    }
    setSaas(num);
  };

  const [payment, setPayment] = useState("");
  const [outPay, setOutPay] = useState("$0");
  const [shown1, setShown1] = useState(false);
  const [smallerPay, setSmallerPay] = useState(0);
  const [display, setDisplay] = useState(0);

  useEffect(() => {
    const temp = payment * 0.0144;
    setOutPay(
      temp
        .toFixed(0)
        .toLocaleString("en-US")
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
    if (temp > 9999999) {
      setSmallerPay(2);
    }
    if (temp > 99999) {
      setSmallerPay(1);
    }
    if (temp <= 99999) {
      setSmallerPay(0);
    }
    if (payment !== "") {
      setShown1(true);
    } else {
      setShown1(false);
    }
  }, [payment]);

  const handleInput2 = (num) => {
    if (num > 999999999999) {
      num = 999999999999;
    }
    setPayment(num);
  };


  useEffect(() => {
    const temp1 = payment * 0.0144;
    const temp2 =  saas * 9.5;
    const temp3 = temp1 + temp2;
    setTotal(
      temp3
        .toFixed(0)
        .toLocaleString("en-US")
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
    
  }, [payment,saas]);

  return (
    <div>
      <div className="max-w-7xl relative mx-auto grid lg:grid-cols-2  gap-12 lg:gap-24 pt-6 pb-24 md:py-24 lg:py-64">
        <div>
          <div className=" px-4 sm:px-6">
            <span className="text-3xl font-bold mb-2">
              See your projected SaaS Revenue Increase & Payments Monetization with Auxo.
            </span>
            <p className="mb-4">
              Enter your current annual SaaS revenue and(or) your annual payment volume to see what partnering
              with Auxo can do for you.
            </p>
            <label >SaaS Revenue</label><br/>
            <input
              max="999999999999"
              type={"number"}
              value={saas}
              onChange={(e) => {
                handleInput(e.target.value);
              }}
              className="h-12 mt-1 mb-2 rounded-xl border px-4 w-full sm:w-72"
              placeholder="Enter annual SaaS Revenue"
            />
          </div>
          <div className=" px-4 sm:px-6">
          <label>Payment Volume</label><br/>
            <input
              min={0}
              max={999999999999}
              maxLength={12}
              type={"number"}
              value={payment}
              onChange={(e) => {
                handleInput2(e.target.value);
              }}
              className="h-12 mt-1 rounded-xl border px-4  w-full sm:w-72"
              placeholder="Enter annual payment volume"
            />
          </div>
        </div>
        <div>
        <div className=" md:rounded-t-3xl border-b bg-white md:shadow-lg px-0 md:mx-6 h-max ">
          <div className="back1 p-6 md:rounded-t-3xl relative">
            <span className="text-2xl font-semibold text-white ">
              You could be making...
            </span>
            <div className="absolute right-6 top-6">
              <img src="/img/auxo-single-white.svg" className="w-8 h-auto" />
            </div>
          </div>
          <div className="grid grid-cols-3 ">
            <div className="p-6 col-span-2">
              <span
                className={
                  " font-bold text-black pb-2 " +
                  (smallerSaas === 2
                    ? "text-xl md:text-3xl"
                    : smallerSaas === 1
                    ? "text-2xl md:text-4xl"
                    : smallerSaas === 0
                    ? "text-3xl md:text-6xl"
                    : "")
                }
              >
                ${outSaas}
              </span>
              <br />
              <span className="text1 text-sm">Projected SaaS Revenue</span>
            </div>
            <div
              className={
                " ml-auto justify-end justify-items-end px-3 py-6 md:px-6  rounded-br-3xl bg-gray-50/50 backdrop-blur-md " +
                (shown ? "block" : "hidden")
              }
            >
              <div className="flex items-center ">
                <span className="text-2xl md:text-4xl text1 font-semibold">
                  950%
                  <br />
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 10l7-7m0 0l7 7m-7-7v18"
                  />
                </svg>
              </div>
              <span className="text-sm">Annually</span>
            </div>
          </div>
        </div>
        <div className=" relative   bg-white md:shadow-lg px-0 md:mx-6 h-max ">
          <div className="grid grid-cols-3 ">
            <div className="p-6 col-span-2">
              <span
                className={
                  " font-bold text-black pb-2 " +
                  (smallerPay === 2
                    ? "text-xl md:text-3xl"
                    : smallerPay === 1
                    ? "text-2xl md:text-4xl"
                    : smallerPay === 0
                    ? "text-3xl md:text-6xl"
                    : "")
                }
              >
                ${outPay}
              </span>
              <br />
              <span className="text1 text-sm">Projected SaaS Revenue</span>
            </div>
            <div
              className={
                " w-[162.32px] ml-auto justify-end justify-items-end px-3 py-6 md:px-6   rounded-br-3xl bg-gray-50/50 backdrop-blur-md " +
                (shown ? "block" : "hidden")
              }
            >
              <div className="flex items-center">
                <span className="text-2xl md:text-4xl text1 font-semibold">
                  Extra
                  <br />
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 10l7-7m0 0l7 7m-7-7v18"
                  />
                </svg>
              </div>
              <span className="text-sm">Income</span>
            </div>
          </div>
        </div>
        <div className="grid items-center justify-items-center relative sm:block md:rounded-b-3xl  md:shadow-lg px-0 md:mx-6 h-max bg-gray-50/50 backdrop-blur-md">
              <p className="text-center py-6 font-bold text-2xl"><span className="text-base font-normal"> Total ROI: </span>${total}</p>
        </div>
        </div>
      </div>
      <ThreeColumn />
      <div className="hidden lg:block">
        <Timeline />
      </div>
      <div className="lg:hidden block">
        <TimelineMobile />
      </div>
      <ContactHome />

    </div>
  );
};

export default Calculate;
