/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Products Launch',
    description:
      'Dedicated teams can be mobilized in as little as 30 days to successfully engage with target customers, effectively communicate your message and grow your brand.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Customer engagement',
    description:
      'Our customer service teams provide activation, market awareness, and activities to enhance brand affinity and increase the lifetime value of your customers.',
    icon: ScaleIcon,
  },
  {
    name: 'Customer acquisition',
    description:
      'Beat quotas, tackle complex market challenges, and expand into new markets with a dedicated sales force that serves as an extension of your brand.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Retention',
    description:
      'Our highly-trained customer retention teams can target your existing accounts to reduce customer churn and save at-risk accounts.',
    icon: AnnotationIcon,
  },
]

export default function Turnkey() {
  return (
    <div className="py-24 xl:py-48 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center grid justify-items-center">
            <img src="/img/key.svg" className='w-24 mb-4'/>
          <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Turn Key Results Through Precision.

          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Sales Partnerships is taking the complexity out of targeted face-to-face business selling and customer engagement for the world’s most admired brands. Here’s how:

          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10 max-w-2xl mx-auto">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md altback1 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
