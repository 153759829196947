import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import AboutPage from "./pages/AboutPage";
import LayoutTransparent from "./layout/LayoutTransparent";
import Story from "./pages/Story";
import Calculate from "./pages/Calculate";


function App() {
  return (
    <>
    <Routes>
      <Route element={<LayoutTransparent />} >
        <Route path="/" element={<Home home />} />
      </Route>

      <Route element={<Layout />} >
        <Route path="/calculate" element={<Calculate />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/story" element={<Story />} />

      </Route>
    </Routes>
</>
  );
}

export default App;
