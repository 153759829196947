import React from 'react'

const Timeline = () => {
  return (
      <>
              <a id="timeline"></a>
    <div className='bg-gray-50 pt-32 pb-48 relative px-4 sm:px-4'>
                <div className='  grid justify-center pb-16 rounded-t-lg text-3xl font-bold text-black text-center border-blue-100'>
            <h2>The SaaS Company Story Timeline</h2>
            <p className='font-normal text-base text1 text-center mt-2 '>A timeline of exactly how we achieved a 1440% increase for our partner.</p>
        </div>
        <div className='relative'>
            <div className='grid grid-cols-5 max-w-7xl mx-auto justify-items-center mb-24 sticky top-[74px]  z-[10] bg-gray-50/70 backdrop-blur-md rounded-b-3xl border-b-2 py-4'>
                <div className='col-span-2'>
                    <span className='font-bold text-2xl text-center text1'>SaaS</span>
                </div>
                <div className='col-span-1'>
                    <img src="/img/auxo-single.svg" className='w-8'/>
                </div>
                <div className='col-span-2'>
                    <span className='font-bold text-2xl text-center text1'>Processing</span>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>
                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>July</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                    <span className=' text-xl text-left'>SaaS company with MRR of approx. $10K</span>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>
                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>Aug</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                    <span className=' text-xl text-left alttext1 font-bold text1'>SaaS Co. approaches Diligent to outsource sales force</span>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>
                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>Sep</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                    <span className=' text-xl text-left'>Create, adapt and implement sales and marketing strategy for SaaS Co.</span>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>
                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>Oct</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                    <span className=' text-xl text-left'>Sales immediately increases and MRR goes from $10K to $15K </span>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 flex justify-end gap-8'>
                    <span className=' text-xl text-left font-bold alttext1'>SaaS company with MRR of approx. $10K</span>
                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                <div className='rounded-full h-6 w-6 back1 '>
                    </div>
                <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>Dec</span>
                </div>
                <div className='col-span-2 grid justify-start'>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>

                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                <span className='bg-gray-50 font-bold text-xl text1 '>Year 2</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                    
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>
                <span className=' text-xl text-left'>Beta tested pricing models for SaaS</span>

                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>Jan</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                    <span className=' text-xl text-left text1'>Growth enables SaaS Co to raise $3.2M in a seed round</span>
                </div>
            </div>
            
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px]  items-center'>
                <div className='col-span-2 grid justify-end'>
                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>May</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                    <span className=' text-xl text-left'>$68K in MRR, Series A begins 12 months earlier than anticipated</span>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>
                                        <span className=' text-xl text-left'>SaaS pricing structure solidified</span>

                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>June</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>
                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>Aug</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                    <span className=' text-xl text-left font-bold alttext1'>SaaS Co takes in $84K in payment processing</span>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>
                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>Sep</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                </div>
            </div>
            <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center'>
                <div className='col-span-2 grid justify-end'>
                <span className=' text-xl text-left font-bold alttext1'>SaaS MRR reaches $70k</span>

                </div>
                <div className='col-span-1 grid justify-center justify-items-center'>
                    
                    <div className='rounded-full h-6 w-6 back1 '>
                        </div>
                    <span className='font-semibold text-xl text-right bg-gray-50 pt-1'>Nov</span>
                    </div>
                <div className='col-span-2 grid justify-start'>
                    <span className=' text-xl text-left font-bold alttext1'>SaaS Company raises $6.5M in Series A Round</span>
                </div>
            </div>
            
            <div className='h-full absolute top-0 right-[50%] z-0 border-l-2 mr-[-1px]'></div>
            <div className='absolute top-[105px] left-[50%] '>
                <span className='bg-gray-50 font-bold text-xl text1 ml-[-23.01px]'>Year 1</span>
            </div>
        </div>
        <div className='absolute top-0 right-[50%] -mr-16'>
            <img src="/img/down-arrow.svg" className='w-32'/>
        </div>
    </div>
    </>
  )
}

export default Timeline