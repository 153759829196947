import { Fragment, useEffect, useState,useRef } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  BriefcaseIcon,
  ChartBarIcon,
  CheckCircleIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MenuIcon,
  NewspaperIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
  ChevronDownIcon,
  MailIcon,
  CashIcon
} from '@heroicons/react/outline'

import { Link } from 'react-router-dom'
import ReactDOM from 'react-dom';

const MenuOver = () => {
  return (
    <div className="max-w-7xl mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start  space-x-6 lg:space-x-10">
    <div>
      <Link to="/" className="flex">
        <span className="sr-only">Auxo</span>
        <img
          className="h-8 w-auto sm:h-10"
          src="/img/auxo-logo-main.svg"
          alt=""
        />
      </Link>
    </div>
    <div className="-mr-2 -my-2 md:hidden">
      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
        <span className="sr-only">Open menu</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </Popover.Button>
    </div>
    <div className="hidden md:flex-1 md:flex md:items-center justify-center">
      <Popover.Group as="nav" className="flex space-x-10 items-center">
        <Popover>
          {() => (
            <>
              <Popover.Button
                className={
                  'text-gray-900 group  rounded-md inline-flex items-center text-base font-medium hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}
              >
                <div >
                <span className='font-[atten-new]'>Calculate ROI</span>
                </div>
                <ChevronDownIcon
                  className={'text-gray-700 ml-2 h-5 w-5 group-hover:opacity-90'}
                  aria-hidden="true"
                />
              </Popover.Button>
            </>
          )}
        </Popover>

<Link to="/story" className={"font-bold font-[atten-new] text-base   hover:opacity-90 text-gray-700"}>
  Story
</Link>
        <Link to="/about" className={"font-[atten-new] text-base font-medium 'text-gray-900' hover:opacity-90 text-gray-900"}>
          Team
        </Link>
        <Link to="/contact" className={"font-[atten-new] text-base font-medium 'text-gray-900' hover:opacity-90 text-gray-900"}>
          Contact
        </Link>
       
      </Popover.Group>
    </div>
    <div className="flex items-center md:ml-12">

<Link
to="/contact"
className=" font-[atten-new] ml-8 inline-flex items-center justify-center px-4 py-2   rounded-md shadow-sm text-base font-medium text-white altback1 hover:opacity-90 border-2 border-sky-900/0"
>
Connect with us.
</Link>
</div>
  </div>

  )
}

export default MenuOver