import React from 'react'
import { Link } from 'react-router-dom'

const Scaling = () => {
  return (
      <div className='back1 py-24 xl:py-48'>
    <div className='  max-w-7xl mx-auto   rounded-lg relative z-10 sm:px-6'>
        <div className='  grid justify-center  pb-12 lg:pb-24 sm:pb-16 rounded-t-lg text-3xl font-bold text-black   px-4 sm:px-6'>
            <img src="/img/chart1.svg" className='w-24 md:justify-self-center mb-4' />
            <h2 className='text-left md:text-center text-white'>How do we help tech companies scale and maximize revenue?</h2>
            <p className='font-normal text-base text-white text-left md:text-center mt-2 '>Take a look at are step-by-step process to growing your revenue.</p>
        </div>
        <div className=' grid lg:grid-cols-2  backdrop-blur-md sm:rounded-3xl overflow-hidden back1'>
        <div className='relative h-max ' >
                <img src="/img/business.jpg" className='md:rounded-t-2xl   shadow-xl'/>
                <div className="absolute z-[2] mr-12 bottom-8 xl:bottom-16 w-full mt-[-120px] grid justify-center">
        <Link
                    to="/contact"
                    className=" font-[atten-new] mt-2 inline-flex items-center justify-center px-4 py-2  border-transparent rounded-md shadow-sm text-base font-medium text-white altback1 hover:opacity-90 border-2 "
                    >
                    Maximize your revenue.
                    </Link>
        </div>

        <div className="absolute left-0 bottom-0 w-full h-24 z-[1] bg-gradient-to-t from-slate-900 to-slate-900/0">

        </div>

            </div>
            <div className='grid p-8 lg:p-12'>
                <div className='flex gap-6 items-center  py-3 border-b border-white/20'>
                    <p className='font-semibold text-white lg:text-lg '>Build and oversee entire customer roadmap from selling, onboarding, implementation, & support</p>
                </div>
                <div className='flex gap-6 items-center  py-3 border-b border-white/20'>
                    <p className='font-semibold text-white lg:text-lg'>Create sales process & pitch</p>
                </div>
                <div className='flex gap-6 items-center  py-3 border-b border-white/20'>
                    <p className='font-semibold text-white lg:text-lg'>Maximize revenue on current customers</p>
                </div>
                <div className='flex gap-6 items-center  py-3 border-b border-white/20'>
                    <p className='font-semibold text-white text-lg'>Develop SaaS pricing models</p>
                </div>
                <div className='flex gap-6 items-center py-3 border-b border-white/20'>
                    <p className='font-semibold text-white lg:text-lg'>Find new customer base & create new revenue streams</p>
                </div>
                <div className='flex gap-6 items-center py-3 '>
                    <p className='font-semibold text-white lg:text-lg'>Monetize payments</p>
                </div>
            </div>
        </div>

    </div>
    </div>
  )
}

export default Scaling