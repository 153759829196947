import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop"


ReactDOM.render(
  <Router>
          <ScrollToTop/>
    <App />
  </Router>,
  document.getElementById('root')
);


