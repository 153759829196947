/* This example requires Tailwind CSS v2.0+ */
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Diligent/SAAS Partnership',
    description:
      'Signed agreement August 2019',
    icon: GlobeAltIcon,
  },
  {
    name: 'SAAS Partner Revenue Increase',
    description:
      '1440% increase in MRR resulting in $154K MRR',
    icon: ScaleIcon,
  },
  {
    name: 'Capital Raise Options Opened',
    description:
      'Raised seed round of $3.2M in Feb 2020 and Series A of $6.5M in Nov 2020',
    icon: LightningBoltIcon,
  },
]

export default function ThreeColumn() {
  return (
    <div className="pt-24 xl:pt-48 pb-24 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 grid   lg:gap-24 items-center relative">
        <div className=' lg:sticky top-[100px]  grid md:justify-center justify-items-center self-start rounded-t-lg text-black  border-blue-100'>
          <img src="/img/auxo-single.svg" className='w-24 mb-8' />
            <h2 className=' text-4xl md:text-5xl text-center'>Our experience speaks for itself.</h2>
            <p className='font-normal text-base text1  mt-2 text-center'>See the huge impact we had on one of our current partners.</p>
            <div className='grid grid-cols-3'>
              <div className='text-center py-6 border-r px-4 md:px-8 my-6'>
                <p className='text-xs sm:text-sm hidden sm:block'>Partnered in</p>
                <p className='text-xs sm:text-sm sm:hidden'>Partnered <br/> in</p>
                <p className='text-2xl md:text-4xl font-bold text1'>2020</p>
              </div>
              <div className='text-center py-6 border-r px-4 md:px-8 my-6'>
                <p className='text-xs sm:text-sm'>MRR increased by</p>
                <p className='text-2xl md:text-4xl font-bold text1'>1440%</p>
              </div>
              <div className='text-center py-6 px-4 md:px-8 my-6'>
                <p className='text-xs sm:text-sm hidden sm:block'>Raised (Seed + A)</p>
                <p className='text-xs sm:text-sm sm:hidden'>Raised <br/> (Seed + A)</p>
                <p className='text-2xl md:text-4xl font-bold text1'>$9.7m</p>
              </div>
            </div>
            <a
                    href="#timeline"
                    className=" font-[atten-new] w-full md:w-auto mt-2 inline-flex items-center justify-center px-4 py-2  border-transparent rounded-md shadow-sm text-base font-medium text-white altback1 hover:opacity-90 "
                    >
                    See the Timeline
                    </a>
        </div>

      </div>
    </div>
  )
}
