import React from 'react'
import { useState,useEffect } from 'react'

const TimelineMobile = () => {

    const [selected,setSelected] =useState("payments")

  return (
      <>
              <a id="timeline"></a>
    <div className='bg-gray-50 pt-32 pb-16 relative'>
                <div className='  grid justify-center pb-16 rounded-t-lg text-3xl font-bold text-black  border-blue-100  px-4 sm:px-6'>
            <h2>The SaaS Company Story Timeline</h2>
            <p className='font-normal text-base text1 sm:text-center mt-2 '>A timeline of exactly how we achieved a 1440% increase for our partner.</p>
        </div>
        <div className='relative'>
            <div className='grid grid-cols-2 max-w-7xl mx-auto justify-items-center mb-24 sticky top-[72px] sm:top-[74px]  z-[10] bg-gray-50/70 backdrop-blur-md  border-b-2 '>
            <div onClick={()=>{setSelected("payments")}} className={'py-4 w-full col-span-1 '  + (selected==="payments" ? "back1 " : "")}>
                    <p className={'cursor-pointer font-bold text-2xl text-center ' + (selected==="payments" ? "text-white" : "text1") }>Processing</p>
                </div>
            <div onClick={()=>{setSelected("saas")}} className={'py-4 w-full col-span-1 '  + (selected==="saas" ? "back1" : "")}>
                    <p className={'cursor-pointer font-bold text-2xl text-center ' +  (selected==="saas" ? "text-white" : "text1")}>SaaS</p>
                </div>
            </div>
            <div className={(selected==="payments"? "block" : "hidden")}>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>
                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>July</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left'>SaaS company with MRR of approx. $10K</span>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>
                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Aug</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left font-bold text1'>SaaS Co. approaches Diligent to outsource sales force</span>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Sep</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left'>Create, adapt and implement sales and marketing strategy for SaaS Co.</span>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Oct</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left'>Sales immediately increases and MRR goes from $10K to $15K </span>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                    <div className='rounded-full h-4 w-4 back1 '>
                        </div>
                    <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Dec</span>
                    </div>
                    <div className='col-span-4 grid justify-start'>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                    <span className='bg-gray-50 font-bold text-lg text1 '>2020</span>
                        </div>
                    <div className='col-span-4 grid justify-start'>
                        
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Jan</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left font-bold text1'>Growth enables SaaS Co to raise $3.2M in a seed round</span>
                    </div>
                </div>
                
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px]  items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>May</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left'>$68K in MRR, Series A begins 12 months earlier than anticipated</span>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>June</span>
                        </div>
                    <div className='col-span-4 grid justify-start'>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Aug</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left font-bold text1'>SaaS Co takes in $84K in MRR</span>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Sep</span>
                        </div>
                    <div className='col-span-4 grid justify-start'>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Nov</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left font-bold text1'>SaaS Company raises $6.5M in Series A Round</span>
                    </div>
                </div>
            </div>


            <div className={(selected==="saas"? "block" : "hidden")}>

                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                    <div className='rounded-full h-4 w-4 back1 '>
                        </div>
                    <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Dec</span>
                    </div>
                    <div className='col-span-4 grid justify-start'>
                    <span className='text-lg text-left font-bold text1 ml-4'>Implement initial SaaS pricing/revenue structure SaaS MRR at $0</span>

                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                    <span className='bg-gray-50 font-bold text-lg text1 '>2020</span>
                        </div>
                    <div className='col-span-4 grid justify-start'>
                        
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Jan</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left '>Beta tested pricing models for SaaS</span>
                    </div>
                </div>
                
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px]  items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>May</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>June</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                    <span className=' text-lg text-left'>SaaS pricing structure solidified</span>

                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Aug</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Sep</span>
                        </div>
                    <div className='col-span-4 grid justify-start'>
                    </div>
                </div>
                <div className='grid grid-cols-5 max-w-7xl mx-auto relative z-[1] py-4 h-[88px] items-center pr-2'>

                    <div className='col-span-1 grid justify-center justify-items-center'>
                        
                        <div className='rounded-full h-4 w-4 back1 '>
                            </div>
                        <span className='font-semibold text-lg text-right bg-gray-50 pt-1'>Nov</span>
                        </div>
                    <div className='col-span-4 grid justify-start ml-4'>
                        <span className=' text-lg text-left font-bold text1'>SaaS MRR reaches $70k</span>
                    </div>
                </div>
            </div>

            
            <div className='h-full absolute top-0 right-[90%] z-0 border-l-2 mr-[-1px]'></div>
            <div className='absolute top-[105px] left-[10%] '>
                <span className='bg-gray-50 font-bold text-xl text1 ml-[-23.01px]'>2019</span>
            </div>
        </div>
        <div className='absolute top-0 right-[50%] -mr-16'>
            <img src="/img/down-arrow.svg" className='w-32'/>
        </div>
    </div>
    </>
  )
}

export default TimelineMobile