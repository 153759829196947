/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  BriefcaseIcon,
  ChartBarIcon,
  CheckCircleIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MenuIcon,
  NewspaperIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
  ChevronDownIcon,
  MailIcon,
  CashIcon
} from '@heroicons/react/outline'

import { Link } from 'react-router-dom'

const solutions = [
  {
    name: 'Calculator ROI',
    description: 'Calculate your projected SaaS revenue increase when you partner with Auxo.',
    href: '/calculate',
    icon: ChartBarIcon,
  },
  {
    name: 'Story',
    description: 'Calculate your projected SaaS revenue increase when you partner with Auxo.',
    href: '/story',
    icon: NewspaperIcon,
  },
  {
    name: 'Team',
    description: 'Calculate your projected SaaS revenue increase when you partner with Auxo.',
    href: '/about',
    icon: UserGroupIcon,
  },
  {
    name: 'Contact',
    description: 'Calculate your projected SaaS revenue increase when you partner with Auxo.',
    href: '/contact',
    icon: MailIcon,
  },

  

]
const callsToAction = [
  { name: 'Connect with us.', href: '/contact', icon: MailIcon },
]
const company = [
  { name: 'About', href: '#', icon: InformationCircleIcon },
  { name: 'Customers', href: '#', icon: OfficeBuildingIcon },
  { name: 'Press', href: '#', icon: NewspaperIcon },
  { name: 'Careers', href: '#', icon: BriefcaseIcon },
  { name: 'Privacy', href: '#', icon: ShieldCheckIcon },
]
const resources = [
  { name: 'Community', href: '#', icon: UserGroupIcon },
  { name: 'Partners', href: '#', icon: GlobeAltIcon },
  { name: 'Guides', href: '#', icon: BookmarkAltIcon },
  { name: 'Webinars', href: '#', icon: DesktopComputerIcon },
]
const blogPosts = [
  {
    id: 1,
    name: 'Boost your conversion rate',
    href: '#',
    preview: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
    imageUrl:
      'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
  },
  {
    id: 2,
    name: 'How to use search engine optimization to drive traffic to your site',
    href: '#',
    preview: 'Eget ullamcorper ac ut vulputate fames nec mattis pellentesque elementum. Viverra tempor id mus.',
    imageUrl:
      'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2300&q=80',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HomeHeader({stick, setHeaderOpen }) {

  const [oneOpen,setOneOpen] = useState(false)

  return (
    <Popover className={"relative transition-all " + (stick ? "shadow-lg" : "")}>
      <div className="absolute inset-0 z-30 pointer-events-none" aria-hidden="true" />
      <div className="relative z-20">
        <div className="max-w-7xl mx-auto grid grid-cols-2 md:flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start  space-x-6 lg:space-x-10">
          <div>
            <Link to="/" className="flex">
              <span className="sr-only">Auxo</span>
              <img
                className="h-8 w-auto sm:h-10"
                src="/img/auxo-logo-main.svg"
                alt=""
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 justify-self-end md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-900">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center justify-center">
            <Popover.Group as="nav" className="flex space-x-10 items-center">
            <Link to="/calculate" className={"font-bold font-[atten-new] text-base  'text-gray-900' hover:opacity-90 " + (oneOpen ? ((stick) ? 'text-gray-700' :'text-gray-700') : ((stick) ? 'text-gray-700' :'text-gray-700'))}>
                Calculate ROI
              </Link>
              <Link to="/story" className={"font-bold font-[atten-new] text-base  'text-gray-900' hover:opacity-90 " + (oneOpen ? ((stick) ? 'text-gray-700' :'text-gray-700') : ((stick) ? 'text-gray-700' :'text-gray-700'))}>
                Story
              </Link>
              <Link to="/about" className=" font-[atten-new] text-base font-bold 'text-gray-900' hover:text-gray-900">
              Team
              </Link>
              <Link to="/contact" className="font-[atten-new] text-base font-bold 'text-gray-900' hover:text-gray-900">
                Contact
              </Link>
              
             
            </Popover.Group>

          </div>
          <div className="flex items-center md:ml-12">

<Link
  to="/contact"
  className="hidden font-[atten-new] ml-8 md:inline-flex items-center justify-center px-4 py-2  rounded-md shadow-sm text-base font-medium text-white altback1 hover:opacity-90 border-2 border-blue-500/0"
>
  Connect with us.
</Link>
</div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5 sm:pb-8">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="/img/auxo-logo-main.svg"
                    alt="Auxo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-900">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 sm:mt-8">
                <nav>
                  <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                    {solutions.map((item) => (
                       <Popover.Button key={item.name}>
                      <Link
                        to={item.href}
                        className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
                      >
                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md back1 text-white sm:h-12 sm:w-12">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                      </Link>
                      </Popover.Button>
                    ))}
                  </div>

                </nav>
              </div>
            </div>
            <div className="py-6 px-5">

              <div className="mt-1">
                <Link
                  to="/contact"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white back1 hover:opacity-90"
                >
                  Connect with us.
                </Link>

              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
