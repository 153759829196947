import CTA from "../components/CTA"
import GetResults from "../components/GetResults"
import Hero2 from "../components/Hero2"
import WhyOutsource from "../components/WhyOutsource"
import Subhead from "../components/Subhead"
import Calculate from "../components/Calculate"
import Turnkey from "../components/Turnkey"


const Home = () => {
  return (
    <div>
        <Hero2/>
        <Subhead/>
        <WhyOutsource/>
        <GetResults/>
        <Turnkey/>
        <Calculate/>
        <CTA/>
    </div>
  )
}

export default Home