import React from 'react'

const Subhead = () => {
  return (    
      <> 
       <a id="more" ></a>
    <div className='relative back1 py-16 px-4 sm:px-6 lg:px-8 overflow-hidden 2xl:-ml-64'>
        <div className='absolute w-96 left-[60%] sm:left-[70%] bottom-0 sm:top-2 -ml-64 z-0 opacity-60 sm:opacity-100'><img  src="/img/aarow.svg"/></div>
        <h2 className='relative z-[1] text-center text-white'>Auxo handles outreach and growth for your business.<br/><span className='text-xl font-normal'>So you can focus on building your product.</span></h2>
    </div>
    </>  
  )
}

export default Subhead