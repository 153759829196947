import {useRef,useState} from 'react'
import { Link } from 'react-router-dom';

const GetResults = () => {

    const ref = useRef();

   

  return (
    <div id="grs" ref={ref} className='py-24 xl:py-48 bg-white px-4 sm:px-6 lg:px-8'>
        <div className='grid md:grid-cols-2 mx-auto max-w-7xl'>
        <div className='justify-self-start mb-12 md:mb-0'>
            <h2 className='text-4xl md:text-5xl mb-2'>We Know SaaS.</h2>
            <p>We handle building your SaaS client base so you can focus on building a better product.</p>
        </div>
        <div className="justify-self-end hidden md:block">
        <Link
                    to="/contact"
                    className="  font-[atten-new] mt-2 inline-flex items-center justify-center px-4 py-2  border-transparent rounded-md shadow-sm text-base font-medium text-white altback1 hover:opacity-90 border-2 "
                    >
                    Boost your SaaS.
                    </Link>
        </div>
        </div>
<div className=' md:grid-cols-4 max-w-7xl m-auto gap-8  mt-12 relative mb-5 hidden md:grid'>
            <div className='grid items-center gap-4'>
            <img src="/img/onboard.svg" className='w-24'/>
                <div className='border-white w-8 h-8 rounded-full z-[1] border-4 altback1 circle-pulse'>

                </div>
            </div>
            <div className='grid items-center gap-4'>
            <img src="/img/prospect.svg" className='w-24'/>
                <div className={'border-white w-8 h-8 rounded-full opacity-100 z-[1] border-4 altback1 circle-pulse duration-[200ms] '}>

                </div>
            </div>
            <div className='grid items-center gap-4'>
            <img src="/img/leads.svg" className='w-24'/>
                <div className={'border-white w-8 h-8 rounded-full opacity-100 z-[1] border-4 altback1 circle-pulse duration-[200ms] '}>

                </div>
            </div>
            <div className='grid items-center gap-4'>
            <img src="/img/close.svg" className='w-24'/>
                <div className={'border-white w-8 h-8 rounded-full opacity-100 z-[1] border-4 altback1 circle-pulse duration-[200ms] '}>

                </div>
            </div>
            <div className={'absolute h-2 altback1 w-[98%] 2xl:w-[100%] left-3 bottom-3 z-0  ease-linear rounded-full  '}></div>
            <div className={'absolute arrow-right w-full opacity-100 right-0 2xl:right-[-29px] bottom-[-3px] duration-[200ms] ' }></div>
        </div>

        <div className='grid md:grid-cols-4 max-w-7xl m-auto gap-8'>
            <div>
            <img src="/img/onboard.svg" className='w-24 block md:hidden'/>
                <h2 className='text-xl font-bold'>Onboarding</h2>
                <p>Auxo digs in deep to learn your products, brand voice, value propositions and goals to develop compelling sales scripts, email sequences and other collateral.</p>
            </div>
            <div>
            <img src="/img/prospect.svg" className='w-24 block md:hidden'/>

                <h2 className='text-xl font-bold'>Prospecting</h2>
                <p>Auxo’s reps can either sell against your database or prospect on their own to find leads. We also work with our marketing team to find new ways to capture leads.</p>
            </div>
            <div>
            <img src="/img/leads.svg" className='w-24 block md:hidden'/>

                <h2 className='text-xl font-bold'>Lead Qualifying</h2>
                <p>Auxo’s sales teams qualify all leads to make sure they’re a perfect match for your products or services — because bad leads aren’t worth your time.</p>
            </div>
            <div>
            <img src="/img/close.svg" className='w-24 block md:hidden'/>

                <h2 className='text-xl font-bold'>Calls & Closing</h2>
                <p>Closing deals take more than a quick phone call. Auxo takes the time to learn what prospects need and nurture those leads through calls and emails.</p>
            </div>

        </div>
        <Link
                    to="/contact"
                    className=" mt-8 md:mt-2 font-[atten-new] w-full md:hidden  inline-flex items-center justify-center px-4 py-2  border-transparent rounded-md shadow-sm text-base font-medium text-white altback1 hover:opacity-90 border-2 "
                    >
                    Boost your SaaS.
                    </Link>
    </div>
  )
}

export default GetResults