import { Link } from "react-router-dom"
export default function CTA() {
    return (
      <div className="bg-slate-900">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-2xl md:text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            <span className="block">Ready to take your business to the next level?</span>
            <span className="block alttext1 text-3xl md:text-2xl">Contact us today.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 w-full sm:w-auto">
            <div className="inline-flex rounded-md shadow w-full sm:w-auto">
              <Link
                to="/contact"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium w-full sm:w-auto rounded-md text-white altback1 hover:opacity-90"
              >
                Connect with us.
              </Link>
            </div>

          </div>
        </div>
      </div>
    )
  }
  